import React from "react";
import styled from "styled-components";
import Clock from "react-live-clock";

const Home = () => {
	return (
		<Content>
			<InnerCont>
				<Div className="hero">
					<HeaderContainer>
						<Header>IQRA IMRAN SYED</Header>
						<ClockContainer>
							MTL{" "}
							<Clock
								format={"HH:mm:ss"}
								ticking={true}
								timezone={"US/Eastern"}
							/>
						</ClockContainer>
					</HeaderContainer>
					<SubHeader>
						<p>Freelance Frontend Developer</p>
						<p>Based in Montreal</p>
					</SubHeader>
				</Div>
			</InnerCont>
		</Content>
	);
};

export default Home;

const Content = styled.div`
	position: relative;
	z-index: 100;
	height: 100%;
	width: 100%;
`;

const InnerCont = styled.div``;

const Div = styled.div`
	position: absolute;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	height: calc(100vh - 4rem);
	width: calc(100vw - 4rem);
	box-sizing: border-box;
`;

const Header = styled.h1`
	color: var(--text-400);
	font-size: 10vw;
	font-family: "Boska", serif;
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	border-top: solid var(--accent-100) 1px;
	border-bottom: solid var(--accent-100) 1px;
	width: calc(100vw - 6rem);
`;

const SubHeader = styled.div`
	color: var(--text-200);
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin: 1rem;
	gap: calc(100vw - 30rem);

	p {
		font-size: 17px;
	}
`;

const ClockContainer = styled.div`
	color: var(--text-400);
	border: solid var(--text-400) 1px;
	margin: 1rem;
	padding: 0.4rem 1rem;
	border-radius: 2rem;
	position: absolute;
	top: 0;
	left: 0;
`;
