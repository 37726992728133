import React, { useState } from "react";
import GlobalStyles from "./Globalstyle";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import styled from "styled-components";
import Spline from "@splinetool/react-spline";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Projects from "./portfolio/Projects";

const App = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [selectedPage, setSelectedPage] = useState("home");

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	const handlePageChange = (page) => {
		setSelectedPage(page);
		setIsSidebarOpen(false);
	};

	const url = "https://prod.spline.design/OdsxnupM9vCKqy5J/scene.splinecode";

	return (
		<>
			<BrowserRouter>
				<GlobalStyles />
				<SplineContainer>
					<SplineBackground scene={url} />
					<Content>
						<InnerCont>
							<CloseButton>
								<button onClick={toggleSidebar}>==</button>
							</CloseButton>
							<Routes>
								<Route path="/" element={<Home />} />
								<Route path="/about" element={<About />} />
								<Route path="/work" element={<Projects />} />
							</Routes>
							<Footer />
						</InnerCont>
					</Content>
				</SplineContainer>
				<Sidebar
					isOpen={isSidebarOpen}
					toggleSidebar={toggleSidebar}
					onPageChange={handlePageChange}
					selectedPage={selectedPage}
				/>
			</BrowserRouter>
		</>
	);
};

export default App;

const SplineContainer = styled.div`
	position: relative;
	height: 100vh;
	width: 100vw;
	overflow-y: hidden;
`;

const SplineBackground = styled(Spline)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
`;

const InnerCont = styled.div`
	border: solid var(--accent-100) 1px;
	margin: 2rem;
	border-radius: 0.3rem;
	height: calc(100vh - 4rem);
	width: calc(100vw - 4rem);
	box-sizing: border-box;
	overflow-y: hidden;
`;

const Content = styled.div`
	position: relative;
	z-index: 100;
	height: 100%;
	width: 100%;
`;

const CloseButton = styled.div`
	font-size: 1.5rem;
	position: absolute;
	top: 1rem;
	right: 5rem;
	z-index: 1000;
	cursor: pointer;

	button {
		background-color: transparent;
		color: var(--text-400);
		border: none;

		&:hover {
			color: var(--text-200);
		}
	}
`;
