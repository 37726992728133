import React, { useState } from "react";
import styled from "styled-components";
import data from "./ProjectData";
import rightArrow from "./asset/right-arrow.svg";

const Projects = () => {
	const [isRotated, setIsRotated] = useState(null);

	const handleProjectClick = (index) => {
		setIsRotated((prev) => (prev === index ? null : index));
	};
	{
		/* <LinkTag
								href={projects.link}
								target="_blank"
								rel="noopener noreferrer"
							>
								↗
							</LinkTag> */
	}
	return (
		<OuterContainer>
			<HeaderContainer>
				<h1>FEATURED WORK</h1>
			</HeaderContainer>
			<InnerContainer>
				{data.map((projects, index) => (
					<>
						<ProjectCard key={index} onClick={() => handleProjectClick(index)}>
							<h4>
								<Span>{projects.num}</Span> {projects.title}
							</h4>
							<Arrow src={rightArrow} isRotated={isRotated === index} />
						</ProjectCard>
						<div>
							{isRotated === index ? <p>{projects.description}</p> : null}
						</div>
					</>
				))}
			</InnerContainer>
		</OuterContainer>
	);
};

export default Projects;

const OuterContainer = styled.div`
	height: 100vh;
	overflow-y: scroll;
	background-color: var(--text-100);
`;

const HeaderContainer = styled.div`
	font-family: "Boska", serif;
	color: var(--text-400);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 5rem;

	h1 {
		font-size: 100px;
	}
`;

const InnerContainer = styled.div`
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const ProjectCard = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 1rem;
	padding: 1rem;
	border-bottom: solid var(--accent-200) 1px;

	h4 {
		font-size: 40px;
		text-align: center;
		margin: 0;
		color: var(--accent-100);
		font-family: "General Sans", sans-serif;
	}

	&:hover {
		background-color: var(--text-200);
		transition: background-color 0.5s linear;
		cursor: pointer;

		h4 {
			color: var(--text-100);
		}
	}

	&:hover span {
		color: var(--text-100);
	}

	&:hover img {
		color: var(--text-100);
	}
`;

const Span = styled.span`
	font-size: 15px;
	color: var(--accent-200);
`;

const LinkTag = styled.a`
	font-size: 2vw;
`;

const Arrow = styled.img`
	transition: transform 0.5s ease;
	transform: ${({ isRotated }) =>
		isRotated ? "rotate(90deg)" : "rotate(0deg)"};
	fill: ${({ isRotated }) => (isRotated ? "black" : "transparent")};
`;
